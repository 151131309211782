import { calculateReportingValue } from '@copper/helpers/currencies';
import { formatCurrency, formatFiat } from '@copper/utils';
import { format } from 'date-fns';
const baseFeesLevelsDict = [
    {
        label: 'Slow',
        value: 'low'
    },
    {
        label: 'Average',
        value: 'medium'
    },
    {
        label: 'Fast',
        value: 'high'
    }
];
const feesFieldsByCurrency = {
    ETH: [
        {
            key: 'gasLimit',
            label: 'Gas limit (units)'
        }
    ]
};
const fieldsByApiParams = {
    feePerByte: {
        key: 'feePerByte',
        label: 'Fee Per Byte',
        editable: true,
        unit: 'sat/B'
    },
    gasPriceGwei: {
        key: 'gasPriceGwei',
        label: 'Gas price',
        editable: true,
        unit: 'GWEI'
    },
    maxPriorityFeePerGas: {
        key: 'maxPriorityFeePerGas',
        label: 'Max priority fee per gas',
        editable: true,
        unit: 'GWEI'
    },
    maxFeePerGas: {
        key: 'maxFeePerGas',
        label: 'Max fee per gas',
        editable: true,
        unit: 'GWEI'
    }
};
const commonFeesFields = [
    {
        key: 'estimatedTime',
        label: 'Estimated time'
    },
    {
        key: 'fee',
        label: 'Estimated fees'
    }
];
const getEstimatedFeesFields = (mainCurrency = '', networkFeesParams) => {
    const fieldsSpecifiedByApi = networkFeesParams?.map((field) => fieldsByApiParams[field.name] ?? null).filter(Boolean) ?? [];
    const uniqForCurrency = feesFieldsByCurrency[mainCurrency] || [];
    return fieldsSpecifiedByApi.concat(uniqForCurrency).concat(commonFeesFields);
};
export const formatFeeEstimatedTime = (value) => {
    const [hours = 0, minutes = 0, seconds = 0] = format(Number(value), 'H:m:s').split(':');
    return Boolean(Number(value))
        ? `≈${Boolean(Number(hours)) ? ` ${hours}h` : ''}${Boolean(Number(minutes)) ? ` ${minutes}m` : ''}${Boolean(Number(seconds)) ? ` ${seconds}s` : ''}`
        : value;
};
export const composeEstimatedFeesData = (mainCurrency = '', data, rateInReporting = '0', networkFeesParams) => getEstimatedFeesFields(mainCurrency, networkFeesParams).map((field) => {
    const fieldValue = data?.[field.key] ?? '0';
    switch (field.key) {
        case 'estimatedTime': {
            return {
                ...field,
                value: formatFeeEstimatedTime(fieldValue)
            };
        }
        case 'fee': {
            return {
                ...field,
                label: field.label,
                value: `${formatCurrency(fieldValue, { decimalPlaces: 8 })} ${data ? (data.feeCurrency ?? '') : (mainCurrency ?? '')}`,
                totalInReporting: formatFiat(calculateReportingValue(fieldValue, rateInReporting))
            };
        }
        default:
            return {
                ...field,
                value: fieldValue
            };
    }
});
export const getFeesLevelOptions = (isEditable = false) => !isEditable
    ? baseFeesLevelsDict
    : [
        ...baseFeesLevelsDict,
        {
            label: 'Custom',
            value: 'custom'
        }
    ];
