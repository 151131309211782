import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { FormResultFrame } from '@copper/components';
import { cancelLoan } from '@copper/entities/lending/lending-reducer';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { Button, ButtonLink, useSnackBar } from '@copper/ui-kit';
import { getErrorData } from '@copper/utils';
import s from './Success.module.css';
export const Success = ({ onDone, onRepeat, loanId }) => {
    const { openSnackbar } = useSnackBar();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { organizationId } = useSelector(selectCurrentOrganization);
    const [submitting, setSubmitting] = useState(false);
    const handleOnCancel = async () => {
        setSubmitting(true);
        try {
            await dispatch(cancelLoan({ organizationId, loanId })).unwrap();
            openSnackbar({ type: 'success', message: 'Loan successfully cancelled' });
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
        finally {
            setSubmitting(false);
        }
    };
    return (_jsxs("div", { className: s.container, children: [_jsx("div", { className: s.frameContainer, children: _jsxs(FormResultFrame, { status: "pending", onDone: onDone, children: [_jsx("div", { className: s.status, children: "Awaiting approval" }), _jsx("div", { className: s.title, children: "Request sent" }), _jsx("div", { className: s.description, children: "Your lending request has been sent to our financing desk." }), _jsx("div", { className: s.viewButton, children: _jsx(Button, { fullWidth: true, size: "l", text: "View request", onClick: () => navigate(`/accounts/all/principal-lending?loanId=${loanId}`), loading: submitting }) }), _jsx(ButtonLink, { text: "Cancel request", onClick: handleOnCancel, disabled: submitting })] }) }), _jsx("div", { className: s.item, children: _jsx("div", { className: s.button, children: _jsx(ButtonLink, { text: "Repeat request", onClick: onRepeat, disabled: submitting }) }) })] }));
};
