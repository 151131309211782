export const getDestinationType = (destination) => {
    if (destination && 'portfolioType' in destination) {
        return destination.portfolioType;
    }
    if (destination && ('cryptoAddressId' in destination || 'nonCustodialWalletId' in destination)) {
        return 'external';
    }
    if (destination && 'counterpartyId' in destination) {
        return 'counterparty';
    }
};
export const checkIsFeesApplyable = (origin, destination, isFiat = false, withdrawTargets) => {
    const destinationType = getDestinationType(destination);
    if (isFiat || !destinationType || !origin?.portfolioType) {
        return false;
    }
    if ([destinationType, origin?.portfolioType].includes('clearloop')) {
        return false;
    }
    if (destinationType === 'trading' && origin?.portfolioType === 'trading') {
        return false;
    }
    if (destination &&
        'portfolioId' in destination &&
        withdrawTargets?.find((target) => target.portfolioId === destination?.portfolioId)?.internal) {
        return false;
    }
    return true;
};
export const checkIsFeeIncludable = (currency = '', fromPortfolioType, feesCurrencies) => {
    if (fromPortfolioType === 'trading') {
        return true;
    }
    if (fromPortfolioType === 'external') {
        return false;
    }
    // 'ARB', 'OP', 'BSC' have another network
    return feesCurrencies.includes(currency);
};
