import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useAddressBookContext } from '@copper/components/AddressBook/context';
import { useCheckPermissionsContext } from '@copper/contexts/checkPermissions';
import { Button, IconWarning } from '@copper/ui-kit';
import { useNavigate } from 'react-router-dom';
import s from './TravelRuleAlert.module.css';
export const TravelRuleAlert = ({ address }) => {
    const navigate = useNavigate();
    const { checkPermissions } = useCheckPermissionsContext();
    const { getBaseUrl } = useAddressBookContext();
    if (!!address.ownerIds?.map((id) => !!id).length &&
        address._embedded?.ownership?.ownershipStatus?.filter((ownership) => ownership.ownerId && ['verified', 'not-required'].includes(ownership.ownershipStatus || '')).length) {
        return;
    }
    const getUrl = () => {
        return `${getBaseUrl(address.organizationId)}/crypto/addresses?addressId=${address.cryptoAddressId}`;
    };
    return (_jsxs("div", { className: s.container, children: [_jsx("div", { className: s.icon, children: _jsx(IconWarning, {}) }), _jsxs(_Fragment, { children: [_jsx("p", { className: s.title, children: "Verification required" }), _jsx("p", { className: s.description, children: "This address isn\u2019t verified. Please verify address ownership." }), checkPermissions(['update-crypto-address-travel-rule']) && (_jsx(Button, { text: "Verify address", onClick: () => navigate(getUrl()), size: "l", fullWidth: true, testId: "button-verify-address" }))] })] }));
};
