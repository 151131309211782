export const STATUS_MAP = {
    UP: {
        color: 'green',
        text: 'All systems operational'
    },
    HASISSUES: {
        color: 'orange',
        text: 'Experiencing issues'
    },
    UNDERMAINTENANCE: {
        color: 'red',
        text: 'Under maintenance'
    }
};
