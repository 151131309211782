import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { AssetIcon, Network } from '@copper/components';
import { DepositAddresses } from '@copper/components/DepositAddresses';
import { hasProxyAddresses } from '@copper/components/DepositAddresses/helpers';
import { DepositDetails } from '@copper/components/DepositDetails';
import { InactiveAccountAlert } from '@copper/components/Forms/InactiveAccountAlert';
import { PortfolioSelect } from '@copper/components/Forms/Portfolio';
import { selectPlatformDepositableCryptoCurrencies } from '@copper/entities/currencies/currencies-selector';
import { selectExchangesEntities } from '@copper/entities/exchanges/exchanges-selector';
import { selectPortfolioList, selectPortfolios } from '@copper/entities/portfolios/portfolios-selector';
import { selectUser } from '@copper/entities/user/user-selector';
import { isExternalPortfolio } from '@copper/helpers/portfolios';
import { useFetchWallets } from '@copper/hooks/useFetchWallets';
import { Alert, FormControl, FormLabel, Select } from '@copper/ui-kit';
import { yupResolver } from '@hookform/resolvers/yup';
import { selectWalledGardenManager } from '@copper/entities/organizations/organizations-selector';
import { selectWalletsByPortfolioId } from '@copper/entities/wallets/wallets-selector';
import { getExchangeMainCurrencies, validationSchema } from './helpers';
export const CryptoForm = ({ currencyId, portfolioId, mainCurrencyId, view = 'primary' }) => {
    const { isAdmin } = useSelector(selectUser);
    const walledGardenManager = useSelector(selectWalledGardenManager);
    const portfolios = useSelector(selectPortfolios);
    const portfolioList = useSelector(selectPortfolioList);
    const walletsByPortfolioId = useSelector(selectWalletsByPortfolioId);
    const exchangesEntities = useSelector(selectExchangesEntities);
    const depositableCryptoCurrencies = useSelector(selectPlatformDepositableCryptoCurrencies);
    const { fetchWalletsForCurrency } = useFetchWallets();
    const formMethods = useForm({
        resolver: yupResolver(validationSchema)
    });
    const { control, reset, watch } = formMethods;
    const { account, currency, mainCurrency } = watch();
    useEffect(() => {
        const currency = depositableCryptoCurrencies.find((item) => item.currency === currencyId);
        // prefill in case of there is only one portfolio, required for oxygen
        const defaultPortfolioId = portfolioList.length === 1 ? portfolioList[0].portfolioId : undefined;
        reset({
            currency,
            mainCurrency: mainCurrencyId,
            account: portfolios[portfolioId ?? defaultPortfolioId ?? '']
        });
    }, [currencyId, mainCurrencyId, portfolioId]);
    useEffect(() => {
        if (!currency?.currency) {
            return;
        }
        fetchWalletsForCurrency(currency);
    }, [currency]);
    return (_jsxs(FormProvider, { ...formMethods, children: [_jsxs("form", { children: [_jsxs(FormControl, { children: [_jsx(FormLabel, { view: view, children: "Asset" }), _jsx(Controller, { control: control, name: "currency", render: ({ field }) => (_jsx(Select, { view: view, placeholder: "Select asset", searchPlaceholder: "Search assets", items: depositableCryptoCurrencies, searchFields: ['name', 'currency'], getKey: (asset) => asset?.currency, getIcon: (asset) => _jsx(AssetIcon, { currency: asset?.currency, size: "s" }), getTopRow: (asset) => asset?.name, getBottomRow: (asset) => asset?.currency, emptySearchString: "No assets match this name", onChange: (asset) => {
                                        reset({
                                            currency: asset,
                                            account
                                        });
                                    }, selected: field.value, reversedStyles: true })) })] }), _jsx(PortfolioSelect, { view: view, name: "account", label: "Account", control: control, mainCurrencyId: mainCurrency, currencyId: currency?.currency, types: ['custody', 'trading', 'fees-vault', 'external'] }), _jsx(InactiveAccountAlert, { portfolio: account, currencyId: currency?.currency, mainCurrencyId: mainCurrency, message: `To deposit ${currency?.currency} in this account, you must first activate the asset.
          ${!isAdmin ? "Please contact Copper's Middle Office for assistance." : ''}` }), _jsx(Network, { view: view, control: control, name: "mainCurrency", currency: currency?.currency, forceMainCurrencies: isExternalPortfolio(account?.portfolioType)
                            ? getExchangeMainCurrencies(exchangesEntities, account, currency?.currency)
                            : undefined, required: true })] }), account && currency && currency.mainCurrencies.length > 1 && mainCurrency && (_jsxs(_Fragment, { children: [_jsx(Alert, { type: "warning", message: "Make sure your deposit and withdrawal networks match or you may lose your deposits." }), _jsx(DepositDetails, { view: view, currency: currency.currency, mainCurrency: mainCurrency })] })), account && currency && mainCurrency && (_jsxs(_Fragment, { children: [_jsx(DepositAddresses, { walletsByPortfolioId: walletsByPortfolioId, portfolios: portfolios, walledGardenManager: walledGardenManager, view: view, currency: currency.currency, mainCurrency: mainCurrency, portfolioId: account.portfolioId, targetType: "direct" }), hasProxyAddresses(account.portfolioType) && (_jsx(DepositAddresses, { walletsByPortfolioId: walletsByPortfolioId, portfolios: portfolios, walledGardenManager: walledGardenManager, view: view, currency: currency.currency, mainCurrency: mainCurrency, portfolioId: account.portfolioId, targetType: "proxy" }))] }))] }));
};
