import BigNumber from 'bignumber.js';
import * as Yup from 'yup';
import { isClearloopSubAccount } from '@copper/helpers/portfolios';
export const validateAmountField = (schema) => {
    const yupObject = schema ?? Yup.string();
    return yupObject
        .test('amount', 'Enter an amount.', (value) => new BigNumber(value ?? 0).gte(0))
        .test({
        name: 'amount',
        test: (value, { parent, createError }) => {
            const currency = parent?.currency?.currency ?? parent?.baseCurrency ?? '';
            return new BigNumber(parent?.availableBalance ?? 0).minus(value ?? 0).gte(0)
                ? true
                : createError({ message: `Insufficient ${currency} balance.` });
        }
    });
};
export const checkRequiredNetwork = ({ origin, destination }) => {
    if (isClearloopSubAccount(origin) ||
        (destination && 'portfolioId' in destination && isClearloopSubAccount(destination))) {
        return false;
    }
    return true;
};
