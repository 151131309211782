import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { useCheckPermissionsContext } from '@copper/contexts/checkPermissions';
import { selectIsAgencyLendingLenderOrganization, selectIsAgencyOrganization, selectIsBilateralLenderOrganization } from '@copper/entities/organizations/organizations-selector';
import { IconGradientOrganisation, IconLogo } from '@copper/ui-kit';
import { FormTypeItem } from './FormTypeItem/FormTypeItem';
import s from './SelectLoanForm.module.css';
export const SelectLoanForm = ({ send }) => {
    const isBilateralLendingLender = useSelector(selectIsBilateralLenderOrganization);
    const isAgencyLendingLenderOrganization = useSelector(selectIsAgencyLendingLenderOrganization);
    const isAgencyOrganization = useSelector(selectIsAgencyOrganization);
    const { isAllowedInAnyPortfolio } = useCheckPermissionsContext();
    const canCreateBilateralLoan = isAllowedInAnyPortfolio(['create-loan', 'create-loan-request']) && isBilateralLendingLender;
    const canCreateParentLoan = isAllowedInAnyPortfolio(['create-parent-loan']) && isAgencyLendingLenderOrganization;
    const canCreateChildLoan = isAllowedInAnyPortfolio(['create-child-loan']) && isAgencyOrganization;
    return (_jsxs("div", { className: s.mainWrapper, children: [_jsx("p", { className: s.title, children: "Select type" }), canCreateBilateralLoan && (_jsx(FormTypeItem, { icon: _jsx(IconGradientOrganisation, {}), title: "Bilateral lending", description: "Lend assets directly to Copper clients", onClick: () => send('BILATERAL'), testId: "lend-bilateral-lending" })), canCreateParentLoan && (_jsx(FormTypeItem, { icon: _jsx(IconLogo, { width: 22, height: 22, viewBox: "0 0 32 32" }), title: "Agency lending", description: "Lend assets through our financing desk", onClick: () => send('LENDER'), testId: "lend-principal-loan-lending" })), canCreateChildLoan && (_jsx(FormTypeItem, { icon: _jsx(IconGradientOrganisation, {}), title: "Agency lending", description: "Lend assets on behalf of lending clients", onClick: () => send('AGENCY'), testId: "lend-child-loan-lending" }))] }));
};
