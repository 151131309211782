import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import cuid from 'cuid';
import { GenerateShardLink } from '@copper/components/GenerateShardLink';
import { selectAccountId } from '@copper/entities/accounts/accounts-selector';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { createPortfolio, fetchPortfolios } from '@copper/entities/portfolios/portfolios-reducer';
import { isFeesVault } from '@copper/helpers/portfolios';
import { Button, FormControl, FormLabel, Input, Radio, useSnackBar } from '@copper/ui-kit';
import { PopupContent } from '@copper/ui-kit/Popup/PopupContent';
import { PopupFooter } from '@copper/ui-kit/Popup/PopupFooter';
import { getErrorData } from '@copper/utils';
import { validationSchema } from './helpers';
import s from './AccountForm.module.css';
export const VaultForm = ({ portfolioType, isDefiVault, onSuccess }) => {
    const dispatch = useDispatch();
    const { openSnackbar } = useSnackBar();
    const portfolioId = useRef(cuid()).current;
    const accountId = useSelector(selectAccountId);
    const { organizationId, organizationType, extra } = useSelector(selectCurrentOrganization);
    const feesVault = isFeesVault(portfolioType);
    const [showOpenNewCU, setShowOpenNewCU] = useState(false);
    const isNewCUEnabled = extra?.newCuEnabled;
    const { register, handleSubmit, formState, control, watch, getValues } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            appType: isNewCUEnabled ? 'new' : 'legacy',
            shardType: feesVault ? 'single-chain-shard' : 'multi-chain-shard'
        }
    });
    const { appType } = watch();
    const { errors, isSubmitting } = formState;
    const onSubmit = async (values) => {
        if (!portfolioType) {
            return;
        }
        try {
            const data = {
                accountId,
                organizationId,
                portfolioId,
                portfolioName: values.name,
                portfolioType,
                isDefiVault,
                isMultiChain: values.shardType === 'multi-chain-shard',
                signingFlowType: appType === 'new' ? 'krypton' : undefined
            };
            await dispatch(createPortfolio(data)).unwrap();
            // update portfolio list for correct sorting
            await dispatch(fetchPortfolios({ organizationId }));
            if (isNewCUEnabled) {
                // temporary disable a new integration
                setShowOpenNewCU(false);
                onSuccess();
            }
            else {
                onSuccess();
            }
            openSnackbar({ type: 'success', message: 'Your account has been added' });
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
    };
    const shardTypes = [
        {
            label: 'Multi-Chain Shard',
            value: 'multi-chain-shard',
            tooltipInfo: 'A shard to access and secure multiple blockchain wallets. One shard for all blockchain assets sharing the same encryption.'
        },
        {
            label: 'Single-Chain Shard',
            value: 'single-chain-shard',
            tooltipInfo: 'A shard to access and secure one blockchain wallet. The wallet can hold all supported assets on that same blockchain.'
        }
    ];
    if (showOpenNewCU) {
        return _jsx(GenerateShardLink, { name: getValues().name, portfolioId: portfolioId });
    }
    return (_jsxs("form", { onSubmit: handleSubmit(onSubmit), children: [_jsxs(PopupContent, { children: [isDefiVault && (_jsx("div", { className: s.description, children: "DeFi vaults let you interact with DeFi apps from the same secure storage as our regular vaults." })), _jsxs(FormControl, { errorMessage: errors.name?.message, children: [_jsx(FormLabel, { children: "Account name" }), _jsx(Input, { size: "l", ...register('name'), maxLength: 128 })] }), isNewCUEnabled && (_jsxs("div", { className: s.appType, children: [_jsx(FormLabel, { children: "Encryption app version" }), _jsx("div", { className: s.smallDescription, children: "Select which version of the Copper Unlimited app you\u2019d like to use to secure your vault." }), _jsx(Controller, { shouldUnregister: true, name: "appType", control: control, render: ({ field }) => (_jsx(Radio, { items: [
                                        {
                                            label: 'Latest version',
                                            value: 'new',
                                            description: 'Use the latest version of the app powered by new MPC technology.'
                                        },
                                        {
                                            label: 'Previous version',
                                            value: 'legacy',
                                            description: 'Use the previous version of the app with legacy encryption technology.'
                                        }
                                    ], value: field.value, onChange: field.onChange, orientation: "vertical", name: "appType" })) })] })), appType === 'legacy' && !feesVault && organizationType === 'standard' && (_jsxs("div", { className: s.shardType, children: [_jsx(FormLabel, { children: "Shard type" }), _jsx("div", { className: s.smallDescription, children: "Select the type of shard used to secure your Vault. Note, you won\u2019t be able to change this later." }), _jsx(Controller, { shouldUnregister: true, name: "shardType", control: control, render: ({ field }) => (_jsx(Radio, { items: shardTypes, value: field.value, onChange: field.onChange, orientation: "vertical", name: "shardType" })) })] }))] }), _jsx(PopupFooter, { rightSection: _jsx(Button, { type: "submit", text: "Create", loading: isSubmitting }) })] }));
};
