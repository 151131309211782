import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import cn from 'classnames';
import { STATUS_MAP } from './constants';
import s from './CopperStatus.module.css';
export const CopperStatus = () => {
    const [status, setStatus] = useState();
    const fetchStatus = async () => {
        try {
            const response = await fetch('https://status.copper.co/summary.json');
            if (response.ok) {
                const data = await response.json();
                setStatus(data?.page?.status);
            }
        }
        catch {
            console.info('There is a problem with retrieving Copper systems status');
        }
    };
    useEffect(() => {
        fetchStatus();
    }, []);
    if (!status)
        return null;
    const { color, text } = STATUS_MAP[status];
    return (_jsx("div", { className: s.container, children: _jsxs("a", { className: s.link, href: "https://status.copper.co", rel: "noopener noreferrer", target: "_blank", children: [_jsx("span", { className: cn(s.indicator, s[`color_${color}`]) }), _jsx("span", { className: s.text, children: text })] }) }));
};
